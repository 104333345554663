<template>
  <ciam-page :title-suffix="$t('pageTitles.api')">
    <ciam-card>
      <ciam-card-header>
        <template #header>
          <span v-once v-html="$t('documentation.title')"></span>
        </template>
        <template #subtitle>
          <span v-once v-html="$t('documentation.subtitle')"></span>
          <br>
          <ciam-text>
            {{ $t('documentation.text') }}
          </ciam-text>
          <ciam-link href="https://documentation.cloud-iam.com/references/api-automation.html#cloud-api-api" target="_blank" :target-icon="false">
            {{ $t('documentation.link') }}
          </ciam-link>
        </template>
        
      </ciam-card-header>
      <ciam-card-content>
        <div ref="container">
          <!-- swagger ui documentation -->
        </div>
      </ciam-card-content>
      <ciam-card-footer></ciam-card-footer>
    </ciam-card>
  </ciam-page>
</template>

<script>
import SwaggerUI from 'swagger-ui';
import 'swagger-ui/dist/swagger-ui.css';
import { config, rawHttp } from '@/http';
import CiamCard from '@/components/CiamCard';
import CiamCardHeader from '@/components/CiamCardHeader';
import CiamCardFooter from '@/components/CiamCardFooter';
import CiamCardContent from '@/components/CiamCardContent';
import CiamLink from '@/components/CiamLink';
import CiamText from '@/components/CiamText';

export default {
  name: 'DocumentationApi',
  components: {
    CiamCardContent,
    CiamCardFooter,
    CiamCardHeader,
    CiamCard,
    CiamLink,
    CiamText
  },
  swaggerUI: null,

  data() {
    return {};
  },

  mounted() {
    let loader = this.$loading.show();

    this.swaggerUI = SwaggerUI({
      url: config.baseURL + '/openapi.json',
      domNode: this.$refs.container,

      docExpansion: 'list',

      showExtensions: true,
      showCommonExtensions: true,

      // if set to true, it triggers infinite redirect loop with keycloak
      deepLinking: false,

      tagsSorter: (tag) => {
        const order = ['deployments', 'providers', 'regions', 'prices'];
        return order.indexOf(tag);
      },

      operationsSorter: 'alpha',

      displayOperationId: false,

      // try out
      displayRequestDuration: true,
      tryItOutEnabled: true,
      // \try out

      requestInterceptor: (req) => {
        req.headers.Authorization = rawHttp.defaults.headers.common['Authorization'];
        return req;
      },

      responseInterceptor: (response) => {
        // hack: remove the info
        if (typeof response === 'object' && response.obj && response.obj.openapi && response.obj.info) {
          delete response.obj.info;
        }

        return response;
      },

      syntaxHighlight: {
        activate: true,
      },

      onComplete: () => {
        loader.hide();
      },
    });
  },
};
</script>

<style lang="scss">
.swagger-ui {
  .info,
  .scheme-container {
    display: none;
  }
}
</style>